body {
    background: #f1c662 url('/++theme++lenart-theme/images/tile.jpg') 50% 0 repeat-x;
}

body#tinymce {
    background: #ffffff;
}

.d-block {
  display: block;
}

.label {
   color: #333333;
   font-size: 100%;
}

.gallery {
  padding-top: 20px;
  .row div {
    padding-bottom: 30px;
    img {
      width: 100%;
    }
  }
}

.plone-nav li a {
    font-size: 15px;
}

.plone-nav li ul li a {
    font-size: 14px;
}

.event.summary {
  display: block;
  max-width: none;
  float: none;
  background: #fafafa;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.17);
  padding: 12px 12px 12px 16px;
  margin-left: 0;
  list-style: none;
  .event-date .timezone {
    display: none;
  }
}

.cal_month {
    font-size: 0.95em;
}

#content-header,
#portal-footer-wrapper,
.outer-wrapper {
  max-width: 100%;
  margin: 0 auto;
  @media (min-width: @plone-screen-sm-min) {
    width: @plone-container-sm;
  }
  @media (min-width: @plone-screen-md-min) {
    width: @plone-container-md;
  }
  @media (min-width: @plone-screen-lg-min) {
    width: @plone-container-lg;
  }
  @media (min-width: @plone-screen-xl-min) {
    width: @plone-container-xl;
  }
}

.outer-wrapper .container {
  max-width: 100%;
  margin: 0 auto;
  @media (min-width: @plone-screen-sm-min) {
    width: @plone-container-sm - @plone-left-toolbar-expanded;
  }
  @media (min-width: @plone-screen-md-min) {
    width: @plone-container-md - @plone-left-toolbar-expanded;
  }
  @media (min-width: @plone-screen-lg-min) {
    width: @plone-container-lg - @plone-left-toolbar-expanded;
  }
  @media (min-width: @plone-screen-xl-min) {
    width: @plone-container-xl - @plone-left-toolbar-expanded;
  }
}

.outer-wrapper {
  background: #ffffff;
  box-shadow: 0 0 3px #999999;
}

#content-header {
  #portal-header {
    margin: 0;
    padding: 25px 0;
  }
}

#portal-searchbox .searchSection {
  display: none;
}

#portal-globalnav-wrapper .container {
  padding: 0;
}

#portal-footer-wrapper .container {
  padding-bottom: 30px;
  a {
    color: #FFFFFF;
    &:hover {
      text-decoration: none;
      border-bottom: 1px dotted white;
    }
  }
}

#mainnavigation {
  width: 100%;
  .plone-navbar {
    margin-bottom: 0px;
    z-index: 100;
  }
  .plone-navbar-nav {
    margin: 0;
  }
}

.plone-breadcrumb {
  margin-top: 0;
  margin-bottom: 0;
}

#slider {
  background: #232323;
  .carousel-inner img {
    width: 100%;
  }
}

#global_statusmessage {
  padding-top: 20px;
  .portalMessage {
    margin-bottom: 0;
  }
}

#main-container {
  padding-top: 20px;
}

#content .month {
  text-transform: capitalize;
}

#cookiemonster {
  max-width: 100%;
  margin: 0 auto;
  background: #232323;
  padding: 15px 15px;
  line-height: 20px;
  color: #ffffff;
  border-bottom: 4px solid #D95B43;
  h4 {
    color: #ffffff;
    font-weight: bold;
    margin-top: 0;
  }
  p {
    color: #bebebe;
  }
  a {
    color: #c60000;
    font-weight: bold;
  }
  ul {
    list-style: none;
    li#accept {
      font-size: 16px;
      padding-bottom: 10px;
    }
  }
}

/* TinyMCE templates */

// image-grid-2x2:
.image-grid-2x2 {
    padding: 0;
    margin: 0 0 @plone-grid-gutter-width 0;
    list-style: none;
    .make-row();

    > li {
        .make-xs-column(12);
        .make-sm-column(6);
        margin-top: @plone-grid-gutter-width;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
